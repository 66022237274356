// @flow

import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { Input } from 'reactstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { some } from 'lodash';

import { Org, OrgUser, SlackChannel } from 'data/entities';
import { CrossbeamPartner } from 'data/entities/crossbeam';
import { fetchCrossbeamPartners } from 'data/repositories/crossbeam';
import { getSlackChannels, updateSlackChannels } from 'data/repositories/slack';
import withOrguser from 'contexts/withOrguser';

import BareLayout from 'layouts/BareLayout';
import CompanyLogo from 'components/CompanyLogo';
import SlackChannelRefreshButton from 'components/SlackChannelRefreshButton';
import SlackChannelSelector from 'components/SlackChannelSelector';

import LoadingPartnerChannels from './LoadingPartnerChannels';

type Props = { loggedInOrg: Org, loggedInOrguser: OrgUser, onboarding?: () => void };

const PartnerSlackChannels = ({ loggedInOrg, loggedInOrguser, onboarding }: Props) => {
  const history = useHistory();

  const [loading, setLoading] = useState(false);
  const [submitting, setSubmitting] = useState(false);
  const [partners, setPartners] = useState([]);
  const [slackChannels, setSlackChannels] = useState<SlackChannel[]>([]);
  const [selectedChannels, setSelectedChannels] = useState<
    { partnerId: string, slackChannel: SlackChannel | null | void }[]
  >([]);

  const fetchChannels = useCallback(() => {
    setLoading(true);
    Promise.all([fetchCrossbeamPartners(), getSlackChannels(true)])
      .then(([cp, c]) => {
        setLoading(false);
        cp.sort((a, b) => a.name.localeCompare(b.name));
        const partnersFiltered = cp.filter((p) => p.syncEnabled);
        setPartners(partnersFiltered);
        setSelectedChannels(
          partnersFiltered.map((p) => ({
            partnerId: p.partner.id,
            slackChannel: p.partner?.slackChannel,
          }))
        );
        setSlackChannels(c.results);
      })
      .catch(() => {
        setLoading(false);
      });
  }, []);

  useEffect(fetchChannels, [fetchChannels]);

  // Filter on search.
  const [search, setSearch] = useState('');
  const filteredPartners: CrossbeamPartner[] = useMemo(
    () =>
      search === ''
        ? partners
        : partners.filter(({ name, domain }) => name?.includes(search) || domain?.includes(search)),
    [partners, search]
  );

  if (!loggedInOrguser.capabilities.canManagePartnerships) {
    history.push('/');
  } else if (onboarding && !loggedInOrg.hasSlackIntegration) {
    onboarding();
    return null;
  }

  const nextStep = () => {
    if (onboarding) {
      onboarding();
    } else {
      history.push('/settings/');
    }
  };

  const onChannelSelect = (partnerId: string, slackChannel: SlackChannel) => {
    setSelectedChannels((sc) =>
      sc.map((s) => (s.partnerId === partnerId ? { partnerId, slackChannel } : s))
    );
  };

  const deletedChannelSelected = some(selectedChannels, (sc) => sc?.slackChannel?.isDeleted);

  const onSave = () => {
    setSubmitting(true);
    updateSlackChannels(
      selectedChannels.map((sc) => ({
        partnerId: sc.partnerId,
        channelId: sc.slackChannel?.id ?? null,
      }))
    )
      .then(() => {
        setSubmitting(false);
        nextStep();
      })
      .catch(() => {
        setSubmitting(false);
      });
  };

  return (
    <BareLayout centered>
      <div className="d-flex flex-column align-items-center gap-30" style={{ minHeight: '95vh' }}>
        <div className="text-center">
          <span className="large-text bold gray-500">Connect your team and partners on Slack</span>
          <h3 className="text-black bold mb-0">Select shared Slack channels to send requests to</h3>
        </div>
        {loading ? (
          <div style={{ width: '650px' }}>
            <LoadingPartnerChannels />
          </div>
        ) : (
          <>
            <div className="d-flex flex-column align-items-center gap-30 flex-1">
              <div className="d-flex flex-row align-items-center gap-15">
                <Input
                  style={{ width: '600px' }}
                  type="text"
                  name="search"
                  placeholder="🔎  Search partners"
                  value={search}
                  onChange={(e) => setSearch(e.target.value)}
                />
              </div>
              {deletedChannelSelected && (
                <div className="p-2 gray-700">
                  <FontAwesomeIcon
                    icon="triangle-exclamation"
                    width={16}
                    height={16}
                    className="orange mr-2"
                  />
                  Some slack connect channels have been deleted, please choose a new channel or
                  remove.
                </div>
              )}
              <div
                className="d-flex flex-row gap-10 p-3 rounded-lg bg-light-blue"
                style={{ width: 650 }}
              >
                <FontAwesomeIcon icon={['far', 'info-circle']} className="blue" fontSize={24} />
                <div className="d-flex flex-column">
                  <span className="large-text bold gray-700 mb-1">
                    To add a channel, please confirm the following:
                  </span>
                  <ul className="normal-text semi-bold gray-600 px-4 m-0">
                    <li className="lh-base">
                      It is a Shared Slack Channel and the Partner has fully joined
                    </li>
                    <li className="lh-base">
                      If it&apos;s a private channel, Crossbeam for Sales is added to the
                      channel&apos;s “Integrations” &gt; “Apps” section
                    </li>
                  </ul>
                </div>
              </div>
              <div className="card card-body p-0 flex-grow-0" style={{ width: 650 }}>
                <table className="custom-table">
                  <thead className="custom-table-header small-text bold gray-600">
                    <tr>
                      <td className="custom-table-cell" width="100%">
                        PARTNER
                      </td>
                      <td className="custom-table-cell text-nowrap py-1">
                        <div className="d-flex flex-row align-items-center">
                          <span className="flex-fill d-flex align-items-center">
                            PARTNER SLACK CHANNEL
                          </span>
                          <SlackChannelRefreshButton onRefresh={fetchChannels} />
                        </div>
                      </td>
                    </tr>
                  </thead>
                  <tbody>
                    {filteredPartners?.length ? (
                      filteredPartners.map((partner) => (
                        <tr key={partner.id} className="custom-table-row">
                          <td className="custom-table-cell" width="100%">
                            <div className="disable-selection d-flex flex-row align-items-center gap-15">
                              <CompanyLogo
                                name={partner.name}
                                logoUrl={partner.logoUrl}
                                domain={partner.clearbitDomain}
                                size={48}
                                className=""
                              />
                              <div className="flex-fill d-flex flex-column">
                                <span className="large-text bold gray-700">{partner.name}</span>
                                <span className="normal-text semi-bold gray-400">
                                  {partner.domain}
                                </span>
                              </div>
                            </div>
                          </td>
                          <td className="custom-table-cell">
                            <SlackChannelSelector
                              channels={slackChannels.filter(({ isDeleted }) => !isDeleted)}
                              selectedChannel={
                                selectedChannels.find((sc) => sc.partnerId === partner.partner.id)
                                  ?.slackChannel
                              }
                              onSelect={(sc) => onChannelSelect(partner.partner.id, sc)}
                              externalOnly
                            />
                          </td>
                        </tr>
                      ))
                    ) : (
                      <tr>
                        <td colSpan="2" className="large-text bold gray-500">
                          No partners were found.
                        </td>
                      </tr>
                    )}
                  </tbody>
                </table>
              </div>
            </div>
            <div className="crossbeam-footer text-center">
              <div className="d-flex flex-row align-items-center gap-20" style={{ width: 800 }}>
                <button
                  type="button"
                  className="btn btn-secondary"
                  onClick={nextStep}
                  disabled={submitting}
                >
                  Cancel
                </button>
                <div className="flex-fill" />
                <button
                  type="button"
                  className="btn btn-primary"
                  onClick={onSave}
                  disabled={deletedChannelSelected || submitting}
                >
                  Save Changes
                </button>
              </div>
            </div>
          </>
        )}
      </div>
    </BareLayout>
  );
};

PartnerSlackChannels.defaultProps = {
  onboarding: null,
};

export default withOrguser(PartnerSlackChannels);
