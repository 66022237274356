// @flow

import * as React from 'react';
import { useCallback, useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import { Button, CustomInput, UncontrolledTooltip } from 'reactstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import { Org } from 'data/entities';
import { BdRequestQuestion } from 'data/entities/bdrequestQuestion';
import { Partner } from 'data/entities/partner';
import {
  getBdRequestQuestions,
  updateBdRequestQuestion,
} from 'data/repositories/bdrequestQuestion';
import { updatePartnerSettings } from 'data/repositories/partners';
import withOrguser from 'contexts/withOrguser';

import QuestionSelector from 'views/BdRequestQuestions/QuestionSelector';
import LoadingRing from 'components/LoadingRing';
import Modal from 'components/Modal';

type Props = {
  open: boolean,
  toggle: () => void,
  partner: Partner,
  refreshPartner: () => void,
  loggedInOrg: Org,
};

const PartnerRequestWorkflowSettings = ({
  open,
  toggle,
  partner,
  refreshPartner,
  loggedInOrg,
}: Props) => {
  // Allow custom questions
  const [allowCustomQuestions, setAllowCustomQuestions] = useState<boolean>(
    partner.settings.requestsWorkflowSettings.allowCustomQuestions
  );
  const canToggleCustomQuestions =
    !loggedInOrg.settings.requestsWorkflowSettings.allowCustomQuestions;
  useEffect(() => {
    if (open)
      setAllowCustomQuestions(partner.settings.requestsWorkflowSettings.allowCustomQuestions);
  }, [partner.settings.requestsWorkflowSettings.allowCustomQuestions, open]);

  // Questions
  const [loading, setLoading] = useState(false);
  const [questions, setQuestions] = useState<BdRequestQuestion[]>([]);
  // Selected questions for adding.
  const [selectedQuestions, setSelectedQuestions] = useState<BdRequestQuestion[]>([]);
  // Questions to display.
  const [currentQuestions, setCurrentQuestions] = useState<BdRequestQuestion[]>([]);
  const getQuestions = useCallback(() => {
    setLoading(true);
    getBdRequestQuestions()
      .then((quest) => {
        setQuestions(quest.results);
        setCurrentQuestions(
          quest.results
            .filter((q) => q.isForAllPartners || q.forPartners.some((p) => p.id === partner.id))
            .sort((a, b) => (b.isForAllPartners ? 1 : -1))
        );
        setLoading(false);
      })
      .catch((error) => {
        setLoading(false);
      });
  }, [partner.id]);
  useEffect(() => {
    if (open) getQuestions();
  }, [getQuestions, open]);

  const [submitting, setSubmitting] = useState(false);
  const addQuestions = () => {
    setSubmitting(true);

    Promise.all(
      selectedQuestions.map((q) =>
        updateBdRequestQuestion(
          q.id,
          q.text,
          q.isForAllPartners,
          q.forPartners.map((p) => p.id).concat(partner.id)
        )
      )
    )
      .then(() => {
        setSelectedQuestions([]);
        setSubmitting(false);
        getQuestions();
      })
      .catch(() => {
        setSubmitting(false);
      });
  };

  const removeQuestion = (question: BdRequestQuestion) => {
    setSubmitting(true);
    updateBdRequestQuestion(
      question.id,
      question.text,
      question.isForAllPartners,
      question.forPartners.map((p) => p.id).filter((p) => p !== partner.id)
    )
      .then(() => {
        setSubmitting(false);
        getQuestions();
      })
      .catch(() => {
        setSubmitting(false);
      });
  };

  const save = () => {
    setSubmitting(true);
    updatePartnerSettings(partner.slug, {
      ...partner.settings,
      requestsWorkflowSettings: {
        allowCustomQuestions,
      },
    })
      .then(() => {
        refreshPartner();
        setSubmitting(false);
        toggle();
      })
      .catch(() => {
        setSubmitting(false);
      });
  };

  return (
    <Modal
      isOpen={open}
      toggle={toggle}
      title={`${partner.name} Co-Selling Templates`}
      footer={
        <>
          <Button color="secondary" disabled={submitting} onClick={toggle}>
            Cancel
          </Button>
          <Button color="primary" disabled={submitting} onClick={save}>
            Save
          </Button>
        </>
      }
    >
      <div className="d-flex flex-column gap-20">
        {/* Allow custom questions */}
        <div className="d-flex flex-row align-items-center gap-25">
          <div
            className="d-flex flex-column flex-fill"
            style={{
              opacity: canToggleCustomQuestions ? 1 : 0.5,
            }}
          >
            <span className="large-text bold gray-700">
              Allow your sales team to ask custom questions
            </span>
            <span className="normal-text semi-bold gray-400">
              If allowed, your sales team will be able to send their own questions in addition to
              the questions you set here.
            </span>
          </div>
          {/* eslint-disable-next-line jsx-a11y/no-noninteractive-tabindex */}
          <div className="form-group complete" tabIndex={0} id="disableOwnerGroup">
            <CustomInput
              id="customQuestions"
              type="switch"
              onChange={() => setAllowCustomQuestions((v) => !v)}
              checked={allowCustomQuestions}
              disabled={submitting || !canToggleCustomQuestions}
              style={{ pointerEvents: !canToggleCustomQuestions && 'none' }}
            />
            {!canToggleCustomQuestions && (
              <UncontrolledTooltip placement="right" target="disableOwnerGroup">
                Custom questions are enabled for all partners in your organization. To configure
                individually for each partner, disable custom questions in Co-Selling Template
                settings.
              </UncontrolledTooltip>
            )}
          </div>
        </div>

        {/* Questions */}
        <div className="d-flex flex-column gap-10">
          <div className="d-flex flex-row align-items-center gap-10">
            <span className="large-text bold gray-700 flex-fill">
              Questions allowed to ask this partner
            </span>
            <Link to="/co-selling-templates" className="btn btn-link p-0 m-0 normal-text bold blue">
              Co-Selling Templates Settings
            </Link>
          </div>

          {/* Add question */}
          <div className="d-flex flex-row align-items-center gap-10">
            <QuestionSelector
              loading={loading}
              questions={questions.filter((q) => !currentQuestions.some((cq) => cq.id === q.id))}
              selectedQuestions={selectedQuestions}
              onSelect={setSelectedQuestions}
            />
            <button
              type="button"
              className="btn btn-primary"
              disabled={selectedQuestions.length === 0 || submitting}
              onClick={() => selectedQuestions.length !== 0 && addQuestions()}
            >
              Add
            </button>
          </div>

          <div className="d-flex flex-column gap-10" style={{ maxHeight: 500, overflow: 'auto' }}>
            {!loading ? (
              <>
                {currentQuestions.length ? (
                  currentQuestions.map((question) => (
                    <div
                      key={question.id}
                      className="card card-body m-0 pl-4 py-3 d-flex flex-row align-items-center gap-10"
                    >
                      <div className="d-flex flex-column flex-fill">
                        <span className="normal-text bold gray-700">{question.text}</span>
                        <span className="normal-text semi-bold gray-400">
                          {question.isForAllPartners
                            ? `${
                                question.isDefault ? 'Default ' : ''
                              }Question allowed for All Partners`
                            : `${question.forPartners.length} Partner${
                                question.forPartners.length === 1 ? '' : 's'
                              } selected`}
                        </span>
                      </div>

                      {/* Remove question */}
                      {!question.isForAllPartners && (
                        <button
                          type="button"
                          className="btn btn-link p-0 m-0 normal-text bold gray-400"
                          onClick={() => removeQuestion(question)}
                          disabled={submitting}
                        >
                          <FontAwesomeIcon icon="xmark" size="lg" />
                        </button>
                      )}
                    </div>
                  ))
                ) : (
                  <div className=" text-center py-4">
                    <span className="normal-text semi-bold gray-700">
                      You have not added questions to ask for this partner.
                      <br />
                      Your sales team will have to write their own custom questions.
                    </span>
                  </div>
                )}
              </>
            ) : (
              <LoadingRing maxWidth="50px" className="p-5" />
            )}
          </div>
        </div>
      </div>
    </Modal>
  );
};

export default withOrguser(PartnerRequestWorkflowSettings);
