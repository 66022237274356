// @flow

import {
  COPY_CONTACT_EMAIL,
  FRONTEND_REQUEST_SOURCE_API,
  GENERIC_FRONTEND_SOURCE,
  NEW_MESSAGE_BUTTON_CLICK,
} from 'data/entities/tracking';
import captureDatadogError from 'utils/captureDatadogError';
import authFetch from 'authFetch/authFetch';
import { checkHttpStatusPromise, parseJSON } from 'authFetch/utils';

export type CopyContactEmailPayload = {
  accountDomain: string,

  apiSource: string,
};

export type NewMessageButtonClickPayload = {
  accountSlug: string,

  partnerSlug: string,

  partnerAccountId: string,

  toOrgUserId: string,
};

const trackAction = (
  action: string,
  data: CopyContactEmailPayload | NewMessageButtonClickPayload
): Promise<any> => {
  const payload = {};
  if (action === COPY_CONTACT_EMAIL) {
    const { accountDomain, apiSource } = data;
    payload.account_domain = accountDomain;
    payload.api_source = apiSource;
  } else if (action === NEW_MESSAGE_BUTTON_CLICK) {
    const { accountSlug, partnerSlug, partnerAccountId, toOrgUserId, apiSource } = data;
    payload.account_slug = accountSlug;
    payload.partner_slug = partnerSlug;
    payload.partner_account_id = partnerAccountId;
    payload.to_orguser_id = toOrgUserId;
    payload.api_source = apiSource || GENERIC_FRONTEND_SOURCE;
  }
  const body = { payload, action, request_source: FRONTEND_REQUEST_SOURCE_API };
  const url = 'track-action';
  return authFetch(url, { method: 'POST', body });
};

const submitHubspotForm = async (formId, args) => {
  const portalId = process.env.REACT_APP_HUBSPOT_PORTALID || '';
  const url = `https://api.hsforms.com/submissions/v3/integration/submit/${portalId}/${formId}`;
  const mapped = [];
  // eslint-disable-next-line guard-for-in
  for (const key in args) {
    const fields = {
      objectTypeId: '0-1',
      name: key,
      value: args[key],
    };
    mapped.push(fields);
  }
  const body = {
    fields: mapped,
    context: {
      pageUri: window.location.href,
      pageName: window.location.pathname,
    },
    legalConsentOptions: {
      consent: {
        consentToProcess: true,
        text: 'I agree to allow Crossbeam to store and process my personal data.',
      },
    },
  };
  return fetch(url, {
    method: 'POST',
    body: JSON.stringify(body),
    headers: {
      'Content-Type': 'application/json',
    },
  })
    .then(checkHttpStatusPromise)
    .then(parseJSON)
    .catch((error) => {
      // Capture the error in Datadog and return the error to the caller
      captureDatadogError(error);
      return Promise.reject(error);
    });
};

export { submitHubspotForm, trackAction };
