// @flow

import type { ReactNode } from 'react';
import * as React from 'react';
import { Link } from 'react-router-dom';
import {
  Button,
  CustomInput,
  DropdownItem,
  DropdownMenu,
  DropdownToggle,
  ModalBody,
  ModalFooter,
  ModalHeader,
  Nav,
  NavItem,
  NavLink,
  UncontrolledDropdown,
  UncontrolledTooltip,
} from 'reactstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { get } from 'lodash';

import { AccountOwner, Partner, PartnerAccount } from 'data/entities';
import { VisibilitySettings } from 'data/entities/org';
import { OrgUserRoles } from 'data/entities/orguser';
import { AccountOverlap, OverlapSharingSettings } from 'data/entities/partner';
import { activeOpp, activeOppRaw, cold, won } from 'data/entities/status';
import type { PartnerApiSettings } from 'data/repositories/partners';
import { getPartnerOverlap, updatePartnerSettings } from 'data/repositories/partners';
import type { ErrorResponse } from 'authFetch/entities';

import { AccountInformation } from 'views/PartnerDetail/PartnerRow';
import FieldErrors from 'components/FieldErrors';
import SegmentSelector from 'components/SegmentSelector';
import NoShareIcon from 'assets/img/NoShare';
import ShareAllIcon from 'assets/img/ShareAll';
import ShareSegmentsIcon from 'assets/img/ShareSegments';

type InfoProps = {
  partnerStatusVisible: boolean,
  orgVisibilitySettings: VisibilitySettings,
  accountOwnerVisible: boolean,
};

function FakeAccountInformation(props: InfoProps) {
  const { orgVisibilitySettings, partnerStatusVisible, accountOwnerVisible } = props;
  const isGlobalStatusHidden = orgVisibilitySettings && orgVisibilitySettings.hideAccountStatus;
  let fakeStatus = null;
  if (partnerStatusVisible && !isGlobalStatusHidden) {
    fakeStatus = activeOppRaw;
  }
  const fakeAccount = new PartnerAccount('fakeId', 'MutualAccount Inc', fakeStatus, null, true);
  const fakeAccountOwner = new AccountOwner(
    'fakeUserId',
    'fakeOrgUserId',
    'John',
    'Partner',
    'john@fakeemail.com',
    OrgUserRoles.SALES
  );
  const accountOwners = accountOwnerVisible ? [fakeAccountOwner] : [];
  return (
    <AccountInformation
      account={fakeAccount}
      isTarget={false}
      accountOwners={accountOwners}
      highlighted
    />
  );
}

type SharingOptionProps = {
  active: boolean,
  icon: ReactNode,
  title: string,
  description: string,
  onClick: () => void,
};
function SharingOption({ title, icon, description, active, onClick }: SharingOptionProps) {
  const Element = active ? 'div' : DropdownItem;
  return (
    <Element
      className={`d-flex flex-row w-100 align-items-center ${
        active ? 'dropdown-toggle justify-content-end' : ''
      }`}
      style={active ? {} : { lineHeight: '20px', padding: '5px 20px' }}
      onClick={active ? undefined : onClick}
    >
      <div className="d-flex flex-row flex-fill">
        <div className={`mr-3 sharing-icon ${active ? 'sharing-icon-active' : ''}`}>{icon}</div>
        <div className="d-flex flex-column align-items-start">
          <span className="sharing-title">{title}</span>
          <span className="fs-14 text-left" style={{ whiteSpace: 'break-spaces' }}>
            {description}
          </span>
        </div>
      </div>
    </Element>
  );
}

const Tab = {
  OVERLAP: 0,
  VISIBILITY: 1,
};

const TabElements = [
  {
    tab: Tab.OVERLAP,
    icon: 'share-alt',
    label: 'Overlap Sharing',
  },
  {
    tab: Tab.VISIBILITY,
    icon: 'eye-slash',
    label: 'Field Visibility',
  },
];

type Props = {
  partner: Partner,
  orgVisibilitySettings: VisibilitySettings,
  toggleState: () => void,
  refreshPartner: ?() => void,
  onUpdate: ?(settings: PartnerApiSettings) => void,
  onboarding: ?boolean,
};

function PartnerMappingSettingsModal(props: Props) {
  const { partner, toggleState, orgVisibilitySettings, onboarding, onUpdate, refreshPartner } =
    props;
  const isGlobalStatusHidden = orgVisibilitySettings && orgVisibilitySettings.hideAccountStatus;
  const [accountStatusVisible, setAccountStatusVisible] = React.useState(false);
  const [accountOwnerVisible, setAccountOwnerVisible] = React.useState(false);
  const [contactsVisible, setContactsVisible] = React.useState(false);
  const [overlap, setOverlap]: [AccountOverlap, () => {}] = React.useState(AccountOverlap.blank());
  const [overlapSharingSetting, setOverlapSharingSetting] = React.useState(null);
  const [overlapSharingSegments, setOverlapSharingSegments] = React.useState(null);
  const [isLoaded, setIsLoaded] = React.useState(false);
  const [activeTab, setActiveTab] = React.useState(Tab.OVERLAP);
  const [errors, setErrors] = React.useState(null);

  React.useEffect(() => {
    if (!onboarding) getPartnerOverlap(partner.slug).then(setOverlap);
  }, [onboarding, partner.slug]);

  React.useEffect(() => {
    setAccountStatusVisible(partner.settings.visibilitySettings.accountStatusVisible);
  }, [partner.settings.visibilitySettings.accountStatusVisible]);

  React.useEffect(() => {
    setAccountOwnerVisible(partner.settings.visibilitySettings.accountOwnerVisible);
  }, [partner.settings.visibilitySettings.accountOwnerVisible]);
  React.useEffect(() => {
    setContactsVisible(partner.settings.visibilitySettings.contactsVisible);
  }, [partner.settings.visibilitySettings.contactsVisible]);
  React.useEffect(() => {
    setOverlapSharingSetting(partner.settings.overlapSharingSetting);
  }, [partner.settings.overlapSharingSetting]);

  React.useEffect(() => {
    setOverlapSharingSegments(partner.overlapSharingSegments);
  }, [partner.overlapSharingSegments]);

  React.useEffect(() => {
    setTimeout(() => {
      setIsLoaded(true);
    });
  }, []);

  const onUpdatePartnerSettings = () => {
    const settings: PartnerApiSettings = {
      overlapSharingSetting,
      overlapSharingSegments: undefined,
      visibilitySettings: { accountStatusVisible, accountOwnerVisible, contactsVisible },
    };

    if (
      overlapSharingSetting === OverlapSharingSettings.SEGMENT &&
      overlapSharingSegments &&
      overlapSharingSegments.length !== 0
    ) {
      settings.overlapSharingSegments = overlapSharingSegments;
    }

    if (onboarding && onUpdate) {
      onUpdate(settings);
      toggleState();
    } else {
      updatePartnerSettings(partner.slug, settings)
        .then(refreshPartner)
        .then(toggleState)
        .catch(({ json }: ErrorResponse) => {
          setActiveTab(Tab.OVERLAP);
          setErrors(json);
        });
    }
  };

  const visibleFields = ['Account Name'];
  if (accountOwnerVisible) {
    visibleFields.push('Account Owners');
  }
  if (accountStatusVisible && !isGlobalStatusHidden) {
    visibleFields.push(`Account Status (${won}, ${activeOpp}, ${cold})`);
  }

  const ShareAll: SharingOptionProps = {
    active: overlapSharingSetting === OverlapSharingSettings.ALL,
    icon: <ShareAllIcon />,
    title: 'Share all overlapping accounts (Recommended)',
    description: 'Share all overlapping accounts with your partner.',
    onClick: () => {
      setOverlapSharingSetting(OverlapSharingSettings.ALL);
    },
  };

  const ShareSegments: SharingOptionProps = {
    active: overlapSharingSetting === OverlapSharingSettings.SEGMENT,
    icon: <ShareSegmentsIcon />,
    title: 'Share segments',
    description: 'Only share certain overlapping accounts.',
    onClick: () => {
      setOverlapSharingSetting(OverlapSharingSettings.SEGMENT);
    },
  };

  const NoShare: SharingOptionProps = {
    active: overlapSharingSetting === null,
    icon: <NoShareIcon />,
    title: 'Do not share any accounts',
    description: 'You will not be sharing anything with this partner.',
    onClick: () => {
      setOverlapSharingSetting(null);
    },
  };

  const Manual: SharingOptionProps = {
    active: overlapSharingSetting === OverlapSharingSettings.MANUAL,
    icon: <FontAwesomeIcon icon="cog" fill="#ffffff" />,
    title: 'Manual Mapping',
    description:
      'This option will soon be discontinued.\nWe advise you to select one of the other options for sharing.',
    onClick: () => {
      setOverlapSharingSetting(OverlapSharingSettings.MANUAL);
    },
  };

  const Options: SharingOptionProps[] = [ShareAll, ...(onboarding ? [] : [ShareSegments]), NoShare];

  return (
    <>
      <ModalHeader style={{ padding: '5px 25px 10px 25px' }} toggle={toggleState}>
        {partner.name} Sharing Settings
      </ModalHeader>
      <ModalBody>
        <Nav tabs fill justified>
          {TabElements.map(({ tab, icon, label }) => (
            <NavItem>
              <NavLink active={activeTab === tab} onClick={() => setActiveTab(tab)}>
                <FontAwesomeIcon icon={icon} style={{ marginRight: '10px' }} />
                {label}
              </NavLink>
            </NavItem>
          ))}
        </Nav>
        <div className="mt-4">
          {activeTab === Tab.OVERLAP && (
            <>
              <h6>How do you want to share accounts?</h6>
              <UncontrolledDropdown>
                <DropdownToggle className="w-100">
                  {[...Options, Manual]
                    .filter((x) => x.active)
                    .map((x) => (
                      <React.Fragment key={x.title}>{SharingOption(x)}</React.Fragment>
                    ))}
                </DropdownToggle>
                <DropdownMenu className="w-100">
                  {Options.filter((x) => !x.active).map((x) => (
                    <React.Fragment key={x.title}>{SharingOption(x)}</React.Fragment>
                  ))}
                </DropdownMenu>
              </UncontrolledDropdown>
              {overlapSharingSetting === OverlapSharingSettings.SEGMENT && (
                <>
                  <hr />
                  <FieldErrors errors={get(errors, 'overlapSharingSegments')} />
                  <div className="d-flex flex-row align-items-center gap-10">
                    <SegmentSelector
                      className="flex-fill"
                      createSegment
                      segment={
                        overlapSharingSegments
                          ? overlapSharingSegments.map((x) => x.value || x)
                          : []
                      }
                      onChange={(result: [Option]) => {
                        setOverlapSharingSegments(
                          result.length !== 0 ? result.map((x) => x.value) : null
                        );
                      }}
                    />
                    or
                    <Link to="/segment">Create Segment</Link>
                  </div>
                </>
              )}
            </>
          )}
          {activeTab === Tab.VISIBILITY && (
            <>
              <h6>Data Sharing Settings</h6>
              {isLoaded && isGlobalStatusHidden && (
                <UncontrolledTooltip
                  placement="left"
                  target="disableStatusGroup"
                  delay={{ show: 250, hide: 200 }}
                >
                  Your organization&apos;s sharing settings do not permit sharing Account status.
                </UncontrolledTooltip>
              )}
              <div className="form-group complete" id="disableStatusGroup">
                <CustomInput
                  id="disableStatus"
                  type="switch"
                  onChange={(e) => setAccountStatusVisible(e.target.checked)}
                  checked={accountStatusVisible && !isGlobalStatusHidden}
                  disabled={isGlobalStatusHidden || overlapSharingSetting === null}
                  label={`Show Customer Status (${won}, ${activeOpp}, ${cold})`}
                />
              </div>
              <div className="form-group complete" id="disableStatusGroup">
                <CustomInput
                  id="disableOwner"
                  type="switch"
                  onChange={(e) => setAccountOwnerVisible(e.target.checked)}
                  checked={accountOwnerVisible}
                  disabled={isGlobalStatusHidden || overlapSharingSetting === null}
                  label="Show account owners"
                />
              </div>
              <div className="form-group complete" id="disableStatusGroup">
                <CustomInput
                  id="contactsVisible"
                  type="switch"
                  onChange={(e) => setContactsVisible(e.target.checked)}
                  checked={contactsVisible}
                  disabled={isGlobalStatusHidden || overlapSharingSetting === null}
                  label="Show contacts"
                />
              </div>
              <h6>What your partner {overlapSharingSetting !== null ? 'will' : 'would'} see:</h6>
              <div
                className="mb-3"
                style={{
                  border: '1px solid #d8dee7',
                  borderRadius: '6px',
                  background: '#f8fafc',
                  padding: '4px',
                }}
              >
                <FakeAccountInformation
                  partnerStatusVisible={accountStatusVisible}
                  accountOwnerVisible={accountOwnerVisible}
                  orgVisibilitySettings={orgVisibilitySettings}
                />
              </div>
            </>
          )}
        </div>
      </ModalBody>
      <ModalFooter>
        {!onboarding && (
          <div className="fs-14 flex-fill">
            {overlap.isCalculated ? (
              <span>{overlap.segments.total.toLocaleString()} overlapping accounts found</span>
            ) : (
              <em>Still Calculating Overlap</em>
            )}
          </div>
        )}
        <Button
          color="primary"
          onClick={
            overlapSharingSetting !== null && activeTab === Tab.OVERLAP
              ? () => setActiveTab(Tab.VISIBILITY)
              : onUpdatePartnerSettings
          }
        >
          {overlapSharingSetting !== null && activeTab === Tab.OVERLAP
            ? 'Continue'
            : 'Save Settings'}
        </Button>
      </ModalFooter>
    </>
  );
}

export default PartnerMappingSettingsModal;
