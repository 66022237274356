// @flow

import * as React from 'react';
import AvatarEditor from 'react-avatar-editor';
import { Alert } from 'reactstrap';
import path from 'path';

import OrgUser from 'data/entities/orguser';
import User from 'data/entities/user';
import { fetchUserAvatar, submitUserAvatar } from 'data/repositories/profile';
import captureDatadogError from 'utils/captureDatadogError';

import Modal from 'components/Modal';

type Props = {
  user: User,
  orguser: OrgUser,
  updateUser: () => void,
};

type State = {
  submit: boolean,
  errorsMessage: string,
  errors: boolean,
  sourceFile: File,
  savedSource: File,
  avatarURL: string,
  scale: number,
  XOffset: number,
  YOffset: number,
  savedScale: number,
  savedX: number,
  savedY: number,
  submitDisabled: boolean,
  toggle: boolean,
  isOpen: boolean,
};

function PlaceholderImage({ user, orguser }) {
  return (
    <img
      className="rounded-circle"
      src={user.avatarImage || orguser?.avatarImage || '/assets/img/avatar-placeholder.png'}
      alt="Current User Avatar"
      width={150}
      height={150}
    />
  );
}

class AvatarForm extends React.Component<Props, State> {
  constructor(props) {
    super(props);
    this.inputFile = React.createRef();
  }

  state = {
    submit: false,
    errorsMessage: '',
    errors: false,
    sourceFile: undefined,
    savedSource: undefined,
    avatarFile: undefined,
    scale: 1.2,
    XOffset: 0.5,
    YOffset: 0.5,
    savedScale: undefined,
    savedX: undefined,
    savedY: undefined,
    submitDisabled: true,
    position: { x: 0.5, y: 0.5 },
    isOpen: false,
  };

  componentDidMount() {
    // Fetch existing avatar configuration

    fetchUserAvatar().then((o) => {
      const sourceFile = this.urlToFile(this.pictureURLFallbacks(o.sourceFile));
      const avatarFile = this.urlToFile(this.pictureURLFallbacks(o.avatarFile));

      Promise.all([sourceFile, avatarFile]).then((resolvedImages) => {
        const candidateSourceFile = resolvedImages[0];
        const newstate = {
          avatarFile: resolvedImages[1],
          scale: o.scale || this.state.scale,
          XOffset: o.XOffset || this.state.XOffset,
          YOffset: o.YOffset || this.state.YOffset,
          position: { x: o.XOffset, y: o.YOffset },
        };
        if (candidateSourceFile.name !== 'avatar-placeholder.png') {
          newstate.sourceFile = candidateSourceFile;
        }
        this.setState(newstate);
      });
    });
  }

  urlToFile = (url) =>
    fetch(url)
      .then((res) => res.blob())
      .then((soBlob) => {
        const soName = path.basename(url);
        const { type } = soBlob;
        return new File([soBlob], soName, { type });
      });

  pictureURLFallbacks = (url) => {
    // If not saved, use the user avatar, or a placeholder
    // Use same fallback pattern for avatarFile and sourceFile

    const { user } = this.props;
    if (url) {
      return url;
    }
    if (user.avatarImage) {
      return user.avatarImage;
    }
    return '/assets/img/avatar-placeholder.png';
  };

  onChange = (event) => {
    // Check for errors and save sourceFile to state if
    // valid
    if (event.target.files.length === 0) {
      return;
    }

    if (this.state.isOpen === false) {
      this.setState({ isOpen: true });
    }

    if (event.target.files[0].size > 5242880) {
      // If file exceeds 5mb, fallback
      // to saved file
      this.setState(
        {
          errorsMessage: 'File is too big! Maximum upload size is 71kb',
        },
        () => {
          this.showErrors();
        }
      );
      event.target.value = '';
    } else {
      // If no errors, save new sourceFile to state and allow
      // submission
      this.setState({ sourceFile: event.target.files[0] }, () => {
        this.canSubmit();
      });
    }
  };

  showSuccess = (data) => {
    this.setState({ submit: true, submitDisabled: true }, () => {
      const { user, updateUser } = this.props;
      user.avatarImage = data.avatar_file;
      updateUser(user);
      this.setState({ submit: false, isOpen: false });
    });
  };

  showErrors = () => {
    this.setState({ submit: false, errors: true, submitDisabled: true }, () => {
      setTimeout(() => {
        this.setState({ errors: false });
      }, 3000);
    });
  };

  prepareAvatar = (event) => {
    event.preventDefault();
    this.saveAvatarConfig();
    this.saveAvatar();
  };

  submitAvatar = () => {
    // Populate new FormData from this.state
    const formData = new FormData();

    formData.append('avatar_file', this.state.avatarFile);

    if (this.state.sourceFile) {
      formData.append('avatar_source_file', this.state.sourceFile);
    }

    formData.append('avatar_scale', this.state.scale);
    formData.append('avatar_x_offset', this.state.XOffset);
    formData.append('avatar_y_offset', this.state.YOffset);

    // Push new avatar and config to server
    submitUserAvatar(formData)
      .then(this.showSuccess)
      .catch((error) => {
        captureDatadogError(error);
      });
  };

  saveAvatarConfig = (position) => {
    if (position && this.editor) {
      const { x, y } = position;
      this.setState({ position });
      this.setState({ XOffset: x });
      this.setState({ YOffset: y });
    }
    this.canSubmit();
  };

  reScale = (event) => {
    this.setState({ scale: Number(event.target.value) });
    this.canSubmit();
  };

  canSubmit = () => {
    if (!this.state.sourceFile) {
      this.setState({ submitDisabled: true });
      return true;
    }
    this.setState({ submitDisabled: false });
    return false;
  };

  saveAvatar = () => {
    // Save scaled image from the editor
    const canvasScaled = this.editor.getImageScaledToCanvas();
    canvasScaled.toBlob((b) => {
      // Files generated are PNG compatible, so prefer .png filenames
      const avatarFile = new File([b], 'Generated-Avatar.png');
      this.setState({ avatarFile }, () => {
        this.submitAvatar();
      });
    });
  };

  // Handle clicking on Upload New Profile Button
  openFileForm = (event) => {
    event.preventDefault();
    this.inputFile.current.click();
  };

  setEditorRef = (editor) => {
    this.editor = editor;
    return editor;
  };

  toggleModalWithPrejudice = (event) => {
    this.setState(
      (prevState) => ({
        sourceFile: prevState.savedSource,
        scale: prevState.savedScale,
        XOffset: prevState.savedX,
        YOffset: prevState.savedY,
        position: { x: prevState.savedX, y: prevState.savedY },
      }),
      () => {
        this.toggleModal(event);
      }
    );
  };

  toggleModal = (event) => {
    if (!this.state.isOpen) {
      this.setState((prevState) => ({
        savedSource: prevState.sourceFile,
        savedScale: prevState.scale,
        savedX: prevState.XOffset,
        savedY: prevState.YOffset,
      }));
    }
    this.setState((prevState) => ({ isOpen: !prevState.isOpen }));
  };

  noOp = (event) => {};

  render() {
    const { submit, sourceFile, scale, submitDisabled, position, errors, errorsMessage, isOpen } =
      this.state;
    const { user, orguser } = this.props;
    return (
      <div>
        <form>
          <div className="container">
            <div className="row">
              <div className="col-sm">
                <div>
                  <a role="button" onClick={this.toggleModal} onKeyDown={this.noOp} tabIndex={0}>
                    <PlaceholderImage user={user} orguser={orguser} />
                  </a>
                </div>
                <Modal
                  isOpen={isOpen}
                  toggle={this.toggleModalWithPrejudice}
                  title="Update Profile Image"
                  footer={
                    <div className="btn-toolbar" role="toolbar">
                      <div className="btn-group mr-2" role="group">
                        <button
                          type="button"
                          className="btn btn-primary"
                          style={{ display: 'inline-block' }}
                          onClick={this.openFileForm}
                          htmlFor="sourceFile"
                        >
                          Upload New Profile Image
                        </button>
                      </div>
                      <div className="btn-group mr-2" role="group">
                        <button
                          type="button"
                          className="btn btn-primary"
                          disabled={submitDisabled}
                          onClick={this.prepareAvatar}
                          style={{ display: 'inline-block' }}
                        >
                          Save
                        </button>
                      </div>
                    </div>
                  }
                  centered
                >
                  <div>
                    {sourceFile ? (
                      <AvatarEditor
                        ref={this.setEditorRef}
                        image={sourceFile}
                        width={150}
                        height={150}
                        border={50}
                        color={[255, 255, 255, 0.6]} // RGBA
                        scale={scale}
                        rotate={0}
                        crossOrigin="anonymous"
                        borderRadius={150}
                        onPositionChange={this.saveAvatarConfig}
                        disableBoundaryChecks={false}
                        position={position}
                      />
                    ) : (
                      <a
                        onClick={this.openFileForm}
                        onKeyDown={this.noOp}
                        role="button"
                        tabIndex={0}
                      >
                        <PlaceholderImage user={user} orguser={orguser} />
                      </a>
                    )}
                  </div>
                  <div>Zoom</div>
                  <div>
                    <input
                      type="range"
                      value={scale || 1.2}
                      className="form-range"
                      id="customRange1"
                      min="1"
                      max="2.25"
                      step="0.01"
                      onChange={this.reScale}
                      style={{ width: '350px' }}
                    />
                    <Alert
                      color="danger"
                      isOpen={errors}
                      toggle={() => this.setState({ errors: !errors, errorsMessage: '' })}
                    >
                      {errorsMessage}
                    </Alert>
                  </div>
                </Modal>
                <input
                  type="file"
                  ref={this.inputFile}
                  name="sourceFile"
                  id="sourceFile"
                  style={{ display: 'none' }}
                  onChange={this.onChange}
                />
              </div>
            </div>
          </div>
          <div />
          <br />
          <Alert color="success" isOpen={submit} toggle={() => this.setState({ submit: !submit })}>
            Updated Profile Picture
          </Alert>
        </form>
      </div>
    );
  }
}

export default AvatarForm;
