// @flow

import * as React from 'react';
import { Alert, Form, FormFeedback, FormGroup, Input, Label } from 'reactstrap';

import Org from 'data/entities/org';
import OrgUser from 'data/entities/orguser';
import User from 'data/entities/user';
import { fetchCrossbeamProfile, updateCrossbeamProfile } from 'data/repositories/profile';
import captureDatadogError from 'utils/captureDatadogError';
import { ErrorResponse } from 'authFetch/entities';

import FieldErrors from 'components/FieldErrors';
import LoadingRing from 'components/LoadingRing';

type Props = {
  org: Org,
  updateUser: (user: User) => void,
  setOrgUser: (orguser: OrgUser) => void,
  onUpdated?: () => void,
};

type State = {
  submit: boolean,
  errorsMessage: { [key: string]: string },
  firstName: string,
  lastName: string,
  pronouns: string,
  department: string,
  title: string,
  errors: boolean,
  loading: boolean,
  updatingProfile: boolean,
};

export const DEPARTMENTS = [
  { value: 'marketing', label: 'Marketing' },
  { value: 'partnerships', label: 'Partnerships' },
  { value: 'revops_or_partner_ops', label: 'Rev Ops/Partner Ops' },
  { value: 'sales', label: 'Sales' },
  { value: 'other', label: 'Something else' },
];

class ProfileForm extends React.Component<Props, State> {
  state = {
    errorsMessage: {},
    firstName: '',
    lastName: '',
    pronouns: '',
    department: '',
    title: '',
    submit: false,
    errors: false,
    loading: false,
    updatingProfile: false,
  };

  componentDidMount() {
    this.loadProfile();
  }

  loadProfile = () => {
    this.setState({ loading: true });
    fetchCrossbeamProfile()
      .then((profile) => {
        const { pronouns, department, firstName, lastName, jobTitle } = profile;
        this.setState({
          pronouns,
          department,
          firstName,
          lastName,
          title: jobTitle,
          loading: false,
        });
      })
      .catch((error: ErrorResponse) => {
        this.setState({
          errorsMessage: error.json,
          errors: true,
          loading: false,
        });
        captureDatadogError(error);
      });
  };

  onChange = (event) => {
    const value = event.target.type === 'checkbox' ? event.target.checked : event.target.value;
    this.setState({ [event.target.name]: value });
  };

  showSuccess = (value) => {
    const { onUpdated } = this.props;
    this.setState({ submit: true, errors: false }, () => {
      const { updateUser, setOrgUser } = this.props;
      updateUser(value.user);
      setOrgUser(value.orguser);
      setTimeout(() => {
        this.setState({ submit: false });
        if (onUpdated) {
          onUpdated();
        }
      }, 2000);
    });
  };

  onUpdateProfile = (event: Event) => {
    event.preventDefault();
    this.setState({ errorsMessage: {} }, this.submitProfile);
  };

  submitProfile() {
    this.setState({ errors: false, errorsMessage: {}, updatingProfile: true });
    const { firstName, lastName, title, pronouns, department } = this.state;
    updateCrossbeamProfile(firstName, lastName, pronouns, department, title)
      .then(this.showSuccess)
      .catch((error: ErrorResponse) => {
        this.setState({
          errorsMessage: error.json,
          errors: true,
          updatingProfile: false,
        });
        captureDatadogError(error);
      });
  }

  render() {
    const {
      submit,
      errorsMessage,
      firstName,
      lastName,
      errors,
      title,
      pronouns,
      department,
      loading,
      updatingProfile,
    } = this.state;
    const { org } = this.props;
    if (loading) return <LoadingRing />;
    return (
      <div>
        <Form>
          <Alert color="success" isOpen={submit} toggle={() => this.setState({ submit: !submit })}>
            Updated Profile
          </Alert>
          <Alert color="danger" isOpen={errors} toggle={() => this.setState({ errors: !errors })}>
            Please fill out the form below
          </Alert>
          <FieldErrors errors={errorsMessage && errorsMessage.nonFieldErrors} />
          <div className="row">
            <FormGroup className="col-6">
              <Label className="form-label-lg" for="firstName">
                First Name
              </Label>
              <Input
                className="form-control"
                placeholder="Enter your first name..."
                id="firstName"
                name="firstName"
                type="text"
                bsSize="lg"
                value={firstName}
                onChange={this.onChange}
                invalid={!!errorsMessage && errorsMessage.firstName}
              />
              <FormFeedback>{errorsMessage && errorsMessage.firstName}</FormFeedback>
            </FormGroup>
            <FormGroup className="col-6">
              <Label className="form-label-lg" for="lastName">
                Last Name
              </Label>
              <Input
                className="form-control"
                placeholder="Enter your last name..."
                id="lastName"
                type="text"
                name="lastName"
                bsSize="lg"
                value={lastName}
                onChange={this.onChange}
                invalid={!!errorsMessage && errorsMessage.lastName}
              />
              <FormFeedback>{errorsMessage && errorsMessage.lastName}</FormFeedback>
            </FormGroup>
            <FormGroup className="col-6">
              <Label className="form-label-lg" for="pronouns">
                Pronouns
              </Label>
              <Input
                className="form-control"
                placeholder="they/them"
                id="pronouns"
                type="text"
                name="pronouns"
                bsSize="lg"
                value={pronouns}
                onChange={this.onChange}
                invalid={!!errorsMessage && errorsMessage.pronouns}
                required={false}
              />
              <FormFeedback>{errorsMessage && errorsMessage.pronouns}</FormFeedback>
            </FormGroup>
            <FormGroup className="col-6">
              <Label className="form-label-lg" for="department">
                Department
              </Label>
              <Input
                className="form-control"
                placeholder="Select department"
                id="department"
                type="select"
                name="department"
                bsSize="lg"
                value={department}
                onChange={this.onChange}
                invalid={!!errorsMessage && errorsMessage.department}
              >
                <option value="">Select department</option>
                {DEPARTMENTS.map((D) => (
                  <option key={D.value} value={D.value}>
                    {D.label}
                  </option>
                ))}
              </Input>
              <FormFeedback>{errorsMessage && errorsMessage.department}</FormFeedback>
            </FormGroup>
            <FormGroup className="col-12">
              <Label className="form-label-lg" for="title">
                Job Title{org.name ? ` at ${org.name}` : ''}
              </Label>
              <Input
                className="form-control"
                placeholder="Enter your job title..."
                id="title"
                type="text"
                name="title"
                bsSize="lg"
                value={title}
                onChange={this.onChange}
                invalid={!!errorsMessage && errorsMessage.title}
                required={false}
              />
              <FormFeedback>{errorsMessage && errorsMessage.title}</FormFeedback>
            </FormGroup>
          </div>

          <button
            type="submit"
            className="btn btn-primary btn-lg text-center font-weight-bold btn-block mt-4"
            onClick={this.onUpdateProfile}
            disabled={updatingProfile}
          >
            Done
          </button>
        </Form>
      </div>
    );
  }
}

ProfileForm.defaultProps = {
  onUpdated: () => {},
};

export default ProfileForm;
