// @flow

import React, { useState } from 'react';
import { Dropdown, DropdownItem, DropdownMenu, DropdownToggle } from 'reactstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import { OrgUser } from 'data/entities';
import { BdRequestFilters } from 'data/repositories/bdrequests';
import withOrguser from 'contexts/withOrguser';

type Props = {
  loggedInOrguser: OrgUser,
  filters: BdRequestFilters,
  onUpdateFilters: (filters: BdRequestFilters) => void,
};

const QuickFilters = ({ loggedInOrguser, filters, onUpdateFilters }: Props) => {
  const [open, setOpen] = useState(false);
  const toggle = () => setOpen(!open);

  const onSelectFilter =
    (field, value, clearFilters = null) =>
    () => {
      const newFilters = Object.keys(filters).reduce((bdf, key) => {
        const clearFilter = clearFilters && clearFilters.includes(key);
        bdf[key] = clearFilter ? null : filters[key];
        return bdf;
      }, new BdRequestFilters());
      newFilters[field] = value;
      onUpdateFilters(newFilters);
    };

  const Button = ({ newValue, field, children, clearFilters }) => (
    <DropdownItem
      className={newValue === filters[field] ? 'bg-light-blue blue' : ''}
      onClick={onSelectFilter(field, newValue, clearFilters)}
    >
      {children}
    </DropdownItem>
  );

  return (
    <>
      <Dropdown toggle={toggle} isOpen={open}>
        <DropdownToggle>
          {filters.forOrguser === '' ? (
            'All Requests'
          ) : (
            <>{filters.following ? 'Following Only' : 'My Requests'}</>
          )}
          <FontAwesomeIcon icon="chevron-down" className="ml-2" />
        </DropdownToggle>
        <DropdownMenu>
          <Button newValue="" field="forOrguser" clearFilters={['following']}>
            All Requests
          </Button>
          <Button
            newValue={loggedInOrguser && loggedInOrguser.id}
            field="forOrguser"
            clearFilters={['following']}
          >
            My Requests
          </Button>
          <Button newValue field="following" clearFilters={['forOrguser']}>
            Following Only
          </Button>
        </DropdownMenu>
      </Dropdown>
    </>
  );
};

export default withOrguser(QuickFilters);
