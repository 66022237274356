// @flow

import React, { useEffect, useMemo, useState } from 'react';
import { useHistory, useLocation } from 'react-router-dom';

import { BdRequest, Org, OrgUser } from 'data/entities';
import { publishToSlack } from 'data/repositories/bdrequests';
import { useSlackChannel } from 'contexts/SlackChannelContext';

import ChannelIsOffsiteInfoText from './ChannelIsOffsiteInfoText';
import RequestIsAlreadyOnSlackInfo from './RequestIsAlreadyOnSlackInfo';
import SlackChannelIsDeletedInfo from './SlackChannelIsDeletedInfo';
import SlackChannelNeedsFullSetupInfo from './SlackChannelNeedsFullSetupInfo';
import SlackChannelNeedsSendingInfo from './SlackChannelNeedsSendingInfo';
import SlackPublishModal from './SlackPublishModal';

type Props = {
  request: BdRequest,
  orguser: OrgUser,
  org: Org,
};

const SlackChannelInfo = (props: Props) => {
  // Controls the "Slack" line on the BasicInfo pane of the Request Detail screen.
  // Is a row in a flex table
  const { request, orguser, org } = props;
  const { selectedChannels } = useSlackChannel();
  const slackChannel = selectedChannels.find(
    (sc) => sc.partnerId === request.partner.id
  )?.slackChannel;
  const [isOpen, setIsOpen] = useState(false);

  const location = useLocation();
  const history = useHistory();

  const query = useMemo(() => new URLSearchParams(location.search), [location.search]);

  useEffect(() => {
    const clearQueryParam = (param) => {
      query.delete(param);
      history.replace({
        search: query.toString(),
      });
    };
    if (query.get('modal')) {
      setIsOpen(true);
      clearQueryParam('modal');
    }
  }, [location, history, query]);

  const toggleModal = () => {
    setIsOpen(!isOpen);
  };

  const handlePublishToSlack = async () => {
    await publishToSlack(request.id);
    setIsOpen(false);
    window.location.reload(true);
  };

  const channelIsDeleted = slackChannel?.isDeleted && !request.slackChannel;
  const requestIsAlreadyOnSlack = request.slackChannel || request.isOffsite;
  // const requestIsAlreadyOnSlack = true;
  const requestShouldBeSentOnSlack = !!slackChannel && !channelIsDeleted;
  const requestIsOffsiteAndYouShouldSetupSlack = request.isOffsite && !slackChannel;
  // const requestIsOffsiteAndYouShouldSetupSlack = true;

  const styles = {
    addAChannel: {
      color: '#4380AA',
      fontSize: '12px',
      fontStyle: 'normal',
      fontWeight: 500,
      letterSpacing: '0.12px',
    },
    sendToSlack: {
      color: '#4380AA',
      fontWeight: 500,
      fontSize: '12px',
      minWidth: 88,
    },
    sendToSlackDisabled: {
      color: '#a0bfd4',
      fontSize: '12px',
      fontWeight: 500,
      textAlign: 'right',
    },
    slackChannelName: {
      overflow: 'hidden',
      whiteSpace: 'noWrap',
      textOverflow: 'ellipsis',
      width: '150px',
      fontWeight: 500,
    },
  };

  return (
    <div className="flex-row">
      <div className="flex d-flex flex-row">
        {/* eslint-disable no-nested-ternary */}
        {channelIsDeleted ? (
          <SlackChannelIsDeletedInfo
            toggleModal={toggleModal}
            orguser={orguser}
            sendToSlackStyle={styles.sendToSlack}
            sendToSlackDisabledStyle={styles.sendToSlackDisabled}
          />
        ) : requestIsAlreadyOnSlack ? (
          <RequestIsAlreadyOnSlackInfo
            slackChannelNameStyle={styles.slackChannelName}
            sendToSlackStyle={styles.sendToSlack}
            request={request}
          />
        ) : requestShouldBeSentOnSlack ? (
          <SlackChannelNeedsSendingInfo
            slackChannel={slackChannel}
            slackChannelNameStyle={styles.slackChannelName}
            sendToSlackStyle={styles.sendToSlack}
            toggleModal={toggleModal}
          />
        ) : (
          <SlackChannelNeedsFullSetupInfo
            slackNoChannelStyle={styles.noChannel}
            sendToSlackStyle={styles.sendToSlack}
            toggleModal={toggleModal}
            addAChannelStyle={styles.addAChannel}
          />
        )}
        {/* eslint-enable no-nested-ternary */}
      </div>

      <SlackPublishModal
        handlePublishToSlack={handlePublishToSlack}
        org={org}
        orguser={orguser}
        isOpen={isOpen}
        toggleModal={toggleModal}
        request={request}
      />
      {channelIsDeleted && (
        <div className="flex d-flex flex-row fs-12 gray-400">
          Slack channel associated with this partner has been deleted.
        </div>
      )}
      {requestIsOffsiteAndYouShouldSetupSlack && (
        <ChannelIsOffsiteInfoText partnerName={request.partner.name} toggleModal={toggleModal} />
      )}
    </div>
  );
};

export default SlackChannelInfo;
