// @flow

import React from 'react';

import { Account, AccountOwner } from 'data/entities';
import { AccountPartnerBase } from 'data/entities/account';
import { ListAccountPartner } from 'data/entities/lists';
import { AccountDetailAccountPartner } from 'data/repositories/accounts';

import CompanyLogo from 'components/CompanyLogo';
import ConnectedPartnerBadge from 'components/ConnectedPartnerBadge';
import Modal from 'components/Modal';
import Signals from 'components/Signals';
import TargetLabel from 'components/TargetLabel';

import AccountOwnerCard from './AccountOwnerCard';

type Props = {
  account: Account | AccountPartnerBase,
  accountPartner: AccountDetailAccountPartner | ListAccountPartner,
  isOpen: boolean,
  toggle: () => void,
};

const PartnersModal = ({ account, accountPartner, isOpen, toggle }: Props) => {
  const { partner, partnerAccount, partnerAccountOwners, isMyTarget, isPartnerTarget, signals } =
    accountPartner;

  const hasAccountOwners = partnerAccountOwners && partnerAccountOwners.length !== 0;

  if (!hasAccountOwners) {
    return null;
  }

  return (
    <Modal
      isOpen={isOpen}
      toggle={toggle}
      title={
        <>
          <div className="d-flex flex-row align-items-center gap-15">
            <CompanyLogo className="m-0" size={48} name={account.name} domain={account.website} />
            <div className="d-flex flex-column gap-5 flex-fill">
              <div className="d-flex flex-row align-items-center gap-10">
                <span className="large-text bold text-black text-truncate">{account.name}</span>
                {partnerAccount.status}
                <TargetLabel isMyTarget={isMyTarget} isPartnerTarget={isPartnerTarget} />
              </div>
              <div className="d-flex flex-row align-items-center gap-5">
                <CompanyLogo
                  className="m-0"
                  size={21}
                  name={partner.name}
                  logoUrl={partner.logoUrl}
                  domain={partner.domain}
                />
                <span className="small-text bold gray-700">{partner.name}</span>
                {partner.isConnected && <ConnectedPartnerBadge small iconOnly />}
              </div>
            </div>
          </div>
          <div className="mt-4">
            <Signals signals={signals} />
          </div>
        </>
      }
    >
      <div className="m-0 d-flex flex-column card card-body">
        <span className="mb-3 large-text bold gray-700">{`People you can contact at ${partner.name}`}</span>
        <div className="d-flex flex-column gap-20">
          {partnerAccountOwners.map((accountOwner: AccountOwner) => (
            <AccountOwnerCard
              accountSlug={account.slug}
              partner={partner}
              partnerAccount={partnerAccount}
              accountOwner={accountOwner}
              key={accountOwner.id}
              compact
            />
          ))}
        </div>
      </div>
    </Modal>
  );
};

export default PartnersModal;
