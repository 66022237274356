// @flow

import React from 'react';
import { Button } from 'reactstrap';

import { BdRequest, Org, OrgUser } from 'data/entities';
import { useSlackChannel } from 'contexts/SlackChannelContext';

import Modal from 'components/Modal';

import PublishToSlackWorkflow from './SlackPublishWorkflow';

type Props = {
  handlePublishToSlack: () => void,
  org: Org,
  orguser: OrgUser,
  isOpen: boolean,
  toggleModal: () => void,
  request: BdRequest,
};

const SlackPublishModal = ({
  handlePublishToSlack,
  org,
  orguser,
  isOpen,
  toggleModal,
  request,
}: Props) => {
  const slackIsSetup = org.hasSlackIntegration;
  const canSetupSlack = !!orguser.capabilities.canManagePartnerships;
  const { selectedChannels } = useSlackChannel();
  const selectedChannel = selectedChannels.find(
    (sc) => sc.partnerId === request.partner.id
  )?.slackChannel;
  const channelIsSetup = !!selectedChannel;
  return (
    <Modal isOpen={isOpen} toggle={toggleModal} title="Send conversation to Slack" centered>
      <PublishToSlackWorkflow
        request={request}
        slackIsSetup={slackIsSetup}
        canSetupSlack={canSetupSlack}
        channelIsSetup={!!request.slackChannel}
      />
      <div className="d-flex justify-content-end mt-3">
        <Button
          color="primary"
          onClick={handlePublishToSlack}
          disabled={!slackIsSetup || !channelIsSetup}
        >
          Send to Slack
        </Button>
      </div>
    </Modal>
  );
};

export default SlackPublishModal;
