// @flow
import { datadogRum } from '@datadog/browser-rum';
import { isNil } from 'lodash';

import { isAbortError } from 'authFetch/utils';

/** Capture Datadog Error
 * Send error to datadog.
 * @param {*} error
 * @returns {boolean} Error handled
 */
const captureDatadogError = (error: any): boolean => {
  if (
    !isNil(error) &&
    !isAbortError(error) &&
    (error.response ? ![404, 403].includes(error.response.status) : true)
  ) {
    datadogRum.addError(error);
    return true;
  }
  return false;
};

export default captureDatadogError;
