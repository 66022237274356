// @flow

import {
  ChugEntity,
  ChugEntityField,
  ConfigFieldMultiValue,
  ConfigSet,
  CorrectBulkImport,
  Integration,
} from 'data/entities/chug';
import type { InitOptions } from 'authFetch/authFetch';
import authFetch from 'authFetch/authFetch';
import { orgUrlBase } from 'authFetch/utils';

// List account entities

export function fetchCSVExportExtraFields(): Promise<ChugEntity> {
  const url = `${orgUrlBase()}/account-entity-fields/`;
  return authFetch(url).then(ChugEntity.fromApi);
}

export function fetchAccountFieldsSchema(): Promise<ChugEntityField[]> {
  const url = `${orgUrlBase()}/crm-integration/config/accounts/schema/`;
  return authFetch(url)
    .then(ChugEntity.fromApi)
    .then((entity) => entity.fields);
}

function setFetchConfigSet(endpoint: string) {
  function fetchConfigSet(): Promise<ConfigSet> {
    const url = `${orgUrlBase()}/crm-integration/config/${endpoint}/`;
    return authFetch(url).then(ConfigSet.fromApi);
  }
  function setConfigSet(config: ConfigSet): Promise<ConfigSet> {
    const url = `${orgUrlBase()}/crm-integration/config/${endpoint}/`;
    const options: InitOptions = { method: 'POST', body: config };
    return authFetch(url, options).then(ConfigSet.fromApi);
  }
  return [fetchConfigSet, setConfigSet];
}

function setFetchConfigFieldMultiValue(endpoint: string) {
  function fetchConfigFieldMultiValue(): Promise<ConfigFieldMultiValue> {
    const url = `${orgUrlBase()}/crm-integration/config/${endpoint}/`;
    return authFetch(url).then(ConfigFieldMultiValue.fromApi);
  }
  function setConfigFieldMultiValue(config: ConfigFieldMultiValue): Promise<ConfigFieldMultiValue> {
    const url = `${orgUrlBase()}/crm-integration/config/${endpoint}/`;
    const options: InitOptions = { method: 'POST', body: config };
    return authFetch(url, options).then(ConfigFieldMultiValue.fromApi);
  }
  function deleteConfigFieldMultiValue(): Promise<any> {
    const url = `${orgUrlBase()}/crm-integration/config/${endpoint}/`;
    const options: InitOptions = { method: 'DELETE' };
    return authFetch(url, options);
  }
  return [fetchConfigFieldMultiValue, setConfigFieldMultiValue, deleteConfigFieldMultiValue];
}

const [fetchCurrentAccountIsCustomerFilters, setAccountIsCustomerFilters] =
  setFetchConfigSet('accounts/is-customer');
const [fetchCurrentAccountExclusionFilters, setAccountExclusionFilters] =
  setFetchConfigSet('accounts/excluded');
const [fetchCurrentAccountIsChurnedFilters, setAccountIsChurnedFilters] =
  setFetchConfigSet('accounts/is-churned');
const [
  fetchCurrentAccountIsTargetFilters,
  setAccountIsTargetFilters,
  deleteAccountIsTargetFilters,
] = setFetchConfigFieldMultiValue('accounts/is-target');

export {
  deleteAccountIsTargetFilters,
  fetchCurrentAccountExclusionFilters,
  fetchCurrentAccountIsChurnedFilters,
  fetchCurrentAccountIsCustomerFilters,
  fetchCurrentAccountIsTargetFilters,
  setAccountExclusionFilters,
  setAccountIsChurnedFilters,
  setAccountIsCustomerFilters,
  setAccountIsTargetFilters,
};

export function setFetchEntityFields(entity: string) {
  function fetchEntity(): Promise<ChugEntity> {
    const url = `${orgUrlBase()}/crm-integration/entities/${entity}/`;
    return authFetch(url).then(ChugEntity.fromApi);
  }
  function setEntity(fields: string[]): Promise<ChugEntity> {
    const url = `${orgUrlBase()}/crm-integration/entities/${entity}/`;
    const options: InitOptions = { method: 'PATCH', body: { fields } };
    return authFetch(url, options).then(ChugEntity.fromApi);
  }
  return [fetchEntity, setEntity];
}

export function fetchLastIntegration(): Promise<?Integration> {
  const url = `${orgUrlBase()}/crm-integration`;
  return authFetch(url).then(({ integration }) =>
    integration ? Integration.fromApi(integration) : null
  );
}

export function triggerIntegrationFirstSync(): Promise<string> {
  const url = `${orgUrlBase()}/crm-integration/first-sync`;
  const options: InitOptions = { method: 'POST', body: {} };
  return authFetch(url, options).then(({ status }) => status);
}

export function bulkUploadAccounts(isPartial: boolean, csvFile: File): Promise<CorrectBulkImport> {
  const url = `${orgUrlBase()}/bulk-upload-accounts/`;
  const body = new FormData();
  body.append('csv_file', csvFile);
  const options = { method: isPartial ? 'PATCH' : 'POST', body };
  return authFetch(url, options).then(CorrectBulkImport.fromApi);
}

export function bulkUploadOpportunities(
  isPartial: boolean,
  csvFile: File
): Promise<CorrectBulkImport> {
  const url = `${orgUrlBase()}/bulk-upload-opportunities/`;
  const body = new FormData();
  body.append('csv_file', csvFile);
  const options = { method: isPartial ? 'PATCH' : 'POST', body };
  return authFetch(url, options).then(CorrectBulkImport.fromApi);
}

export function setUpHotGlueIntegration(provider: string, flowId: any): Promise<any> {
  const url = `${orgUrlBase()}/crm-integration`;
  const options = { method: 'POST', body: { provider, flow_id: flowId } };
  return authFetch(url, options);
}
