// @flow
import {
  fetchAccountIdsByXBRecordId,
  listPartnerMappedAccountsByAccount,
} from 'data/repositories/accounts';
import { fetchPartnerIdsByXBRecordId } from 'data/repositories/partners';

import type { RequestParams } from './NewQuestionBdRequest';

type ProcessedRequestParams = {
  accountSlug: string | null,
  partnerSlug: string | null,
  partnerAccountId: string | null,
};

// eslint-disable-next-line import/prefer-default-export
export const processRequestParams = async (
  params: RequestParams
): Promise<ProcessedRequestParams> => {
  const { xbRecordId, xbPartnerOrgId, xbPartnerRecordId, account, partner, partnerAccountId } =
    params;
  const promises = [];

  // If we have params with XB IDs we need to convert them to our IDs
  // Account slug.
  if (xbRecordId) {
    promises.push(fetchAccountIdsByXBRecordId(xbRecordId).then((r) => r.slug));
  } else {
    promises.push(Promise.resolve(null));
  }

  // Partner slug.
  if (xbPartnerOrgId) {
    promises.push(fetchPartnerIdsByXBRecordId(xbPartnerOrgId).then((r) => r.slug));
  } else {
    promises.push(Promise.resolve(null));
  }

  // Partner account ID.
  if (xbPartnerRecordId && partner && account) {
    promises.push(
      listPartnerMappedAccountsByAccount(partner, account, null, xbPartnerRecordId).then((r) =>
        r.length > 0 ? r[0].partnerAccount?.id : null
      )
    );
  } else {
    promises.push(Promise.resolve(null));
  }

  return Promise.all(promises).then((results) => ({
    accountSlug: results[0] ?? account,
    partnerSlug: results[1] ?? partner,
    partnerAccountId: results[2] ?? partnerAccountId,
  }));
};
