// @flow

import * as React from 'react';
import { useEffect, useState } from 'react';
import { Button, CustomInput } from 'reactstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { isEqual } from 'lodash';

import { Org, SlackChannel } from 'data/entities';
import { ConversationSettings, Partner } from 'data/entities/partner';
import { updatePartnerSettings } from 'data/repositories/partners';
import { useSlackChannel, WithSlackChannelSelector } from 'contexts/SlackChannelContext';
import withOrguser from 'contexts/withOrguser';

import LoadingRing from 'components/LoadingRing';
import Modal from 'components/Modal';

type Props = {
  partner: Partner,
  refreshPartner: () => void,
  loggedInOrg: Org,
};

const PartnerConversationsSettingsModal = ({ partner, refreshPartner, loggedInOrg }: Props) => {
  const [open, setOpen] = useState(false);
  const toggle = () => setOpen(!open);
  const [submitting, setSubmitting] = useState(false);

  const [conversationSettings, setConversationSettings] = useState<ConversationSettings>(
    partner.settings.conversationSettings
  );

  const { hasSlackIntegration, settings } = loggedInOrg;
  const [selectedSlackChannel, setSelectedSlackChannel] = useState<SlackChannel | null | void>(
    partner.slackChannel
  );
  const { selectedChannels, onSave } = useSlackChannel();

  useEffect(() => {
    const localSelectedChannel = selectedChannels.find(
      (sc) => sc.partnerId === partner.id
    )?.slackChannel;
    setSelectedSlackChannel(localSelectedChannel);
  }, [selectedChannels, partner]);

  useEffect(() => {
    if (open) setConversationSettings(partner.settings.conversationSettings);
  }, [partner.settings.conversationSettings, open]);

  const updateConversationSettings = () => {
    setSubmitting(true);
    const promises = [
      updatePartnerSettings(partner.slug, {
        ...partner.settings,
        conversationSettings,
      }),
      onSave(selectedChannels),
    ];
    Promise.all(promises)
      .then(() => {
        refreshPartner();
        setSubmitting(false);
        toggle();
      })
      .catch(() => {
        setSubmitting(false);
      });
  };

  const toggleEnableDirectConversations = () =>
    setConversationSettings({
      ...conversationSettings,
      enabledDirectConversations: !conversationSettings.enabledDirectConversations,
    });

  const canSave =
    !isEqual(partner.settings.conversationSettings, conversationSettings) ||
    !isEqual(partner.slackChannel, selectedSlackChannel);

  return (
    <>
      <button className="btn btn-secondary" type="button" onClick={toggle}>
        <FontAwesomeIcon icon="cog" className="mr-2" /> Inbox Settings
      </button>
      <Modal
        isOpen={open}
        toggle={toggle}
        title={`${partner.name} Inbox Settings`}
        footer={
          !submitting ? (
            <>
              <Button color="secondary" onClick={toggle}>
                Cancel
              </Button>
              <Button color="primary" onClick={updateConversationSettings} disabled={!canSave}>
                Save
              </Button>
            </>
          ) : (
            <LoadingRing maxWidth="30px" />
          )
        }
      >
        <div className="d-flex flex-column gap-25">
          <div className="d-flex flex-row align-items-center gap-25">
            <div className="d-flex flex-column">
              <span className="large-text bold gray-700">Enable Direct Shared Conversations</span>
              <span className="normal-text semi-bold gray-500">
                Allow anyone from my org to start shared conversations with this partner.
              </span>
            </div>
            <div className="form-group complete" id="disableOwnerGroup">
              <CustomInput
                id="enableShared"
                type="switch"
                onChange={toggleEnableDirectConversations}
                checked={conversationSettings.enabledDirectConversations}
              />
            </div>
          </div>
          {hasSlackIntegration && settings.conversationsOnSharedSlackChannels && (
            <div className="d-flex flex-column gap-5">
              <span className="large-text bold gray-700">Partner Shared Slack Channel</span>
              <WithSlackChannelSelector
                partnerId={partner.id}
                disabled={false}
                autoSave
                showSubText
              />
            </div>
          )}
        </div>
      </Modal>
    </>
  );
};

export default withOrguser(PartnerConversationsSettingsModal);
