// @flow
import React, { useEffect, useMemo, useState } from 'react';
import { Link } from 'react-router-dom';
import { Dropdown, DropdownMenu, DropdownToggle } from 'reactstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import { Org, OrgUser } from 'data/entities';
import { CrossbeamSource } from 'data/entities/crossbeam';
import { CoSellingHealth as CoSellingHealthType } from 'data/entities/org';
import { getCrossbeamSources } from 'data/repositories/crossbeam';
import { getCoSellingHealth } from 'data/repositories/orgs';
import withOrguser from 'contexts/withOrguser';

import LoadingRing from 'components/LoadingRing';
import Pill from 'components/Pill';
import SlackLogo from 'components/SlackLogo';
import HealthIndicator from 'assets/img/HealthIndicator';

import { isUsingPreset } from './CoSellPreset';

type ItemProps = {
  icon: any,
  title: string,
  description: string,
  pill: any,
  link: string,
  complete?: boolean,
};

const Item = ({ icon, title, description, pill, link, complete }: ItemProps) => {
  const Component = link.startsWith('/') ? Link : 'a';
  return (
    <Component
      className={`d-flex flex-row align-items-center border rounded-lg gap-10${
        !complete ? ' card-hoverable' : ''
      }`}
      style={{
        padding: '12px 16px',
        opacity: complete ? 0.5 : 1,
        pointerEvents: complete ? 'none' : undefined,
      }}
      to={link}
      href={link}
      target="_blank"
    >
      <div
        className="border rounded-lg d-flex align-items-center justify-content-center gray-600"
        style={{ minWidth: 48, minHeight: 48, maxWidth: 48, maxHeight: 48 }}
      >
        {icon}
      </div>
      <div className="d-flex flex-column gap-5 flex-fill">
        <span className="normal-text bold gray-700">{title}</span>
        <span className="small-text gray-600">{description}</span>
      </div>
      {complete ? <Pill color="green" icon={<FontAwesomeIcon icon="check" />} /> : pill}
    </Component>
  );
};

Item.defaultProps = {
  complete: false,
};

type Props = {
  loggedInOrg: Org,
  loggedInOrguser: OrgUser,
};

const CoSellingHealth = ({ loggedInOrg, loggedInOrguser }: Props) => {
  const [isOpen, setIsOpen] = React.useState(false);
  const toggle = () => setIsOpen(!isOpen);

  const [health, setHealth] = useState<CoSellingHealthType | null>(null);
  const [sources, setSources] = useState<CrossbeamSource[]>([]);
  useEffect(() => {
    if (loggedInOrg) {
      getCrossbeamSources()
        .then((s) => setSources(s?.items || []))
        .catch(() => setSources([]));
      getCoSellingHealth().then((h) => setHealth(h));
    }
  }, [loggedInOrg, loggedInOrguser]);

  const usingDataPreset = useMemo(() => isUsingPreset(sources), [sources]);

  const items = useMemo(
    () =>
      health && loggedInOrg
        ? [
            /* {
              icon: <FontAwesomeIcon icon="arrow-right-arrow-left" />,
              title: 'Use Co-Sell Data Preset',
              description: 'Get the most out of your co-selling experience with our data preset',
              pill: <Pill label="Not Using" />,
              complete: usingDataPreset,
              percentage: usingDataPreset ? 1 : 0,
              link: `${process.env.REACT_APP_CROSSBEAM_URL}/data-sources`,
            }, */
            {
              icon: <FontAwesomeIcon icon="book" />,
              title: 'Co-Selling Templates',
              description: 'Add your own questions and context',
              pill: (
                <Pill
                  label={`${health.customQuestionsCount}/${health.needsCustomQuestions}`}
                  color={health.customQuestionsCount === 0 ? 'red' : 'gray'}
                />
              ),
              complete: health.customQuestionsCount >= health.needsCustomQuestions,
              percentage: health.customQuestionsCount / health.needsCustomQuestions,
              link: '/co-selling-templates',
            },
            {
              icon: <SlackLogo />,
              title: loggedInOrg.hasSlackIntegration
                ? 'Add Shared Slack Channels'
                : 'Install our Slack App',
              description: 'Connect your team and partners on Slack',
              pill: (
                <Pill
                  label={
                    loggedInOrg.hasSlackIntegration
                      ? `${health.partnersWithSlackChannels}/${health.totalPartners}`
                      : 'Not Installed'
                  }
                />
              ),
              complete:
                loggedInOrg.hasSlackIntegration &&
                health.partnersWithSlackChannels >= health.totalPartners,
              percentage:
                (loggedInOrg.hasSlackIntegration ? 0.5 : 0) +
                health.partnersWithSlackChannels / health.totalPartners / 2,
              link: loggedInOrg.hasSlackIntegration ? '/slack-channels' : '/settings',
            },
            {
              icon: <FontAwesomeIcon icon="link" />,
              title: 'Collateral Documents',
              description: 'Add partner enablement collateral for your sales team',
              pill: (
                <Pill
                  label={`${health.partnersWithCollateralLinks}/${health.totalPartners}`}
                  color={health.partnersWithCollateralLinks > 0 ? 'gray' : 'red'}
                />
              ),
              complete: false,
              percentage: health.partnersWithCollateralLinks / health.totalPartners,
              link: '/network/p?status=sync_enabled',
            },
          ]
        : [],
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [loggedInOrg, usingDataPreset, health]
  );

  const percentage = useMemo(
    () =>
      Math.round(
        items.reduce((acc, item) => acc + Math.min(item.percentage || 0, 1) / items.length, 0) * 100
      ),
    [items]
  );
  const color = useMemo(
    // eslint-disable-next-line no-nested-ternary
    () => (percentage <= 50 ? 'red' : percentage < 80 ? 'yellow' : 'green'),
    [percentage]
  );

  return (
    <Dropdown className="pull-right disable-selection" isOpen={isOpen} toggle={toggle}>
      <DropdownToggle className={isOpen ? 'py-1 px-0 rounded-lg bg-gray-100' : 'p-0'} color="link">
        <div className="rounded-lg px-2 d-flex flex-row align-items-center gap-5">
          {health ? (
            <HealthIndicator height={32} width={32} color={color} />
          ) : (
            <LoadingRing maxWidth="32px" />
          )}
          <div className="d-flex flex-column align-items-start">
            <span className="normal-text bold">{percentage}%</span>
            <span
              className="text-xs gray-500 text-nowrap"
              style={{ fontSize: '10px', lineHeight: 'normal' }}
            >
              Co-Selling Setup
            </span>
          </div>
        </div>
      </DropdownToggle>
      <DropdownMenu right className="px-3 py-3 flex-column" style={{ width: 410 }} onClick={toggle}>
        <span className="large-text bolder gray-700">Co-Selling Setup</span>
        <div className="d-flex flex-column align-items-center justify-content-center gap-10">
          <HealthIndicator height={62} width={62} color={color} />
          <span className="bolder gray-700" style={{ fontSize: 20 }}>
            {percentage}%
          </span>
        </div>
        <div className="d-flex flex-column gap-10 pt-4">
          <span className="normal-text bold gray-700">Improve your score:</span>
          {items.map((item, index) => (
            <Item
              // eslint-disable-next-line react/no-array-index-key
              key={index}
              title={item.title}
              description={item.description}
              complete={item.complete}
              link={item.link}
              pill={React.cloneElement(item.pill, { className: 'text-nowrap' })}
              icon={React.cloneElement(item.icon, { style: { width: 25, height: 25 } })}
            />
          ))}
        </div>
      </DropdownMenu>
    </Dropdown>
  );
};

export default withOrguser(CoSellingHealth);
