/* eslint-disable no-console */
// @flow

/* global chrome */

import * as React from 'react';
import { Alert, Col, Row } from 'reactstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import { Org } from 'data/entities';
import { OrgUserContext } from 'contexts';
import captureDatadogError from 'utils/captureDatadogError';
import { chromeExtensionID } from 'utils/environment';
import authFetch from 'authFetch/authFetch';

import LoadingRing from 'components/LoadingRing';

const EXTENSION_NOT_FOUND =
  'Could not establish connection. Do you have the Chrome Extension installed?';
const FAILED_TO_PUSH = 'Something went wrong connecting with the Chrome Extension.';

const pushTokenToChrome = (apiToken: string): Promise<void> => {
  // Push a message to the Crossbeam for Sales chrome extension with the latest API token
  const newReadableError = (errorMessage?: string): Error =>
    new Error(errorMessage || FAILED_TO_PUSH);

  return new Promise((resolve, reject) => {
    const successCallback = (response) => {
      if (!response) {
        const chromeError = chrome?.runtime?.lastError?.message;
        console.log('chrome.runtime.lastError.message', chromeError);
        if (chromeError === 'Could not establish connection. Receiving end does not exist.') {
          return reject(newReadableError(EXTENSION_NOT_FOUND));
        }
        const error = newReadableError();
        captureDatadogError(chromeError);
        return reject(error);
      }

      const { status } = response;
      if (status === 200) {
        return resolve();
      }
      console.log('Received something other than 200 response from extension: ', response);
      return reject(newReadableError());
    };

    try {
      chrome.runtime.sendMessage(
        chromeExtensionID,
        { partneredApiKey: apiToken, store: true },
        undefined,
        successCallback
      );
    } catch (e) {
      console.log('Failure to sendMessage', e);
      reject(newReadableError());
    }
  });
};

export const getExtensionTokenAndPush = (): Promise<any> =>
  authFetch('auth/extension-token', { method: 'POST' }).then(({ token }) =>
    pushTokenToChrome(token)
  );

const LinkedInIcon = (props) => (
  <FontAwesomeIcon icon={['fab', 'linkedin']} color="#0A66C2" {...props} />
);

const SalesforceIcon = (props) => (
  <FontAwesomeIcon icon={['fab', 'salesforce']} color="#45A0DA" {...props} />
);

type Props = {
  org: Org,
};

function ConnectExtension(props: Props) {
  const [isLoading, setIsLoading] = React.useState(true);
  const [errorStr, setError] = React.useState(null);
  const { org } = props;

  React.useEffect(() => {
    // Fetch the long lived token, push it, register callback that sets some state of success?
    getExtensionTokenAndPush()
      .then(() => {
        setIsLoading(false);
      })
      .catch((error: Error) => {
        if (error?.message) {
          setError(error.message);
        } else {
          setError(FAILED_TO_PUSH);
        }
        setIsLoading(false);
      });
  }, []);

  return (
    <div className="ConnectExtension">
      <div className="mx-auto mt-5 col-12">
        {isLoading && <LoadingRing text="Connecting extension..." />}
        {errorStr && (
          <Alert color="danger" className="p-5 text-center">
            {errorStr}
          </Alert>
        )}
        {!isLoading && !errorStr && (
          <>
            <Alert color="primary" className="p-2 text-center">
              <h4>
                <strong>Ready!</strong> The Crossbeam for Sales Chrome extension is setup
              </h4>
            </Alert>
            <div className="text-center my-5">
              <h4>Put it to work today!</h4>
              <h5>
                Use the Crossbeam for Sales Chrome extension to discover partner overlap with
                accounts <br />
                on <LinkedInIcon /> LinkedIn
              </h5>
            </div>
            <div className="row justify-content-center">
              {!!(org && org.lastIntegration && org.lastIntegration.provider === 'SALESFORCE') && (
                <div className="col-sm-6">
                  <div className="card">
                    <div className="card-body padding-30">
                      <Row>
                        <Col sm="auto">
                          <SalesforceIcon style={{ fontSize: '70px' }} />
                        </Col>
                        <Col>
                          <h4>Try it on Salesforce</h4>
                        </Col>
                      </Row>
                      <hr />
                      <h5>How it works</h5>
                      <p>
                        Navigate to any Salesforce Account or Opportunity. The Crossbeam for Sales
                        Chrome Extension will show you the overlapping partner accounts.
                      </p>
                    </div>
                  </div>
                </div>
              )}
              <div className="col-sm-6">
                <div className="card">
                  <div className="card-body padding-30">
                    <Row>
                      <Col sm="auto">
                        <LinkedInIcon style={{ fontSize: '70px' }} />
                      </Col>
                      <Col>
                        <h4>Try it while Prospecting on LinkedIn</h4>
                      </Col>
                    </Row>
                    <hr />
                    <h5>How it works</h5>
                    <p>
                      <strong>LinkedIn Individual Profile</strong>
                    </p>
                    <p>
                      While prospecting individuals, the Crossbeam for Sales Chrome Extension will
                      show you partners who work with the company that the person is employed by.
                    </p>
                    <p>
                      <strong>Company page, or Company Sales Navigator page</strong>
                    </p>
                    <p>
                      The Crossbeam for Sales Chrome Extension will show you the overlapping partner
                      accounts.
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </>
        )}
      </div>
    </div>
  );
}

export default function ConnectExtensionContainer() {
  return (
    <OrgUserContext.Consumer>{({ org }) => <ConnectExtension org={org} />}</OrgUserContext.Consumer>
  );
}
