// @flow

import * as React from 'react';
import { Link } from 'react-router-dom';

import NetworkProfile from 'data/entities/networkprofile';

import PersonAvatar from 'components/PersonAvatar';

type Props = {
  networkProfile: NetworkProfile,
};

const NetworkCard = ({ networkProfile }: Props) => {
  const { id, fullName, firstName, lastName, overlapsCount, org, title, avatarImage } =
    networkProfile;
  return (
    <div className="card card-hoverable mb-4">
      <Link to={`/network/oid/${id}`} className="flex-fill" disabled>
        <div className="d-flex flex-row align-items-center card-body">
          <PersonAvatar
            firstName={firstName}
            lastName={lastName}
            avatar={avatarImage}
            org={org}
            size={65}
          />
          <div className="d-flex flex-column">
            <div className="bold large-text gray-700">{fullName}</div>
            <div className="semi-bold normal-text gray-500">
              {title ? `${title} at ${org.name}` : org.name}
            </div>
            {overlapsCount && (
              <div className="semi-bold normal-text gray-400">
                {overlapsCount} similar mutual accounts
              </div>
            )}
          </div>
        </div>
      </Link>
    </div>
  );
};

export default NetworkCard;
